/* src/assets/css/style.css */

.about-top {
  padding-top: 80px;
  width: 70%;
  margin: 0 auto;
}

.menu-title {
  font-size: 16px;
  cursor: pointer;
  color: #999;
}

.icon-close {
  cursor: pointer;
}

.icon-close:hover {
color: #7A1777;
font-size: large;
}

.menu-title:hover {
  font-size: 17px;
  font-weight: bold;
  color: #333;
}



.top-menu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background: #f8f8f8;
  margin-left: 10px;
}

.top-buttons button {
  border: none;
  cursor: pointer;
  background-color: #f8f8f8;
}

.top-buttons button:hover {
  background-color: #acacac;
  /* Example hover background color */
}

.navbar-brand img {
  width: 70%;
}

.navbar-toggler {
  display: none;
}

.navbar-nav {
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  margin-left: 15px;
}

.nav-link {
  text-decoration: none;
  color: #000;
}

.main {
  padding: 0 20px;
  margin-top: 76px;
}


.pengenalan-container {

  display: flex;
  flex-direction: column;
  padding-top: 50px;
  justify-content: center;
  align-items: center;
}

.pengenalan-atas,
.pengenalan-masjid {
  display: flex;
}

.mula-container {
  display: flex;
  padding: 70px 0 0 0;
}

.ciri-container {
  display: flex;
  padding-top: 70px;
  justify-content: center;
  align-items: center;
}

.pengenalan-kiri {
  flex: 1;

}

.pengenalan-kanan {
  flex: 1;
  text-align: center;
}

.pengenalan-bawah {
  padding-top: 40px;
  text-align: center;

}


.pengenalan-masjid-bawah {
  flex: 1;
  text-align: center;
}


.pengenalan-masjid-bawah h2 {
  margin-top: 0px;
}

.pengenalan-masjid-bawah span {
  margin: 0 20px;
}

.img-masjid,
.img-jemaah {
  width: 70%;
}


.fixed-button {
  position: fixed;
  bottom: 20px;
  /* Adjust as needed */
  right: 20px;
  /* Adjust as needed */
  z-index: 1000;
  /* Adjust as needed */
}

.fixed-button button {
  background-color: #7A1777;
  /* Example background color */
  color: white;
  padding: 10px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.fixed-button button:hover {
  background-color: #9A1999;
  /* Darker background color on hover */
}



.img-konsep {
  width: 400px;
}



.top-menu ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.top-menu li {
  margin-right: 20px;
}

.top-menu a {
  text-decoration: none;
  color: #333;

}

.top-menu a:hover {
  font-weight: bold;
}







.ciri-kiri {
  flex-direction: column;
  flex: 1;

}

.ciri-kanan {
  flex-direction: column;
  flex: 1;
  margin: 0 20px;



}


.kos-container {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
}

.kos-head {
  text-align: center;
  padding: 0 40px;
}

.kos-etikaf {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sistem-etikaf {
  background-color: #e8e8e8;
  text-align: center;
  padding: 40px;
  border-top-left-radius: 50px;
  /* Adjust the value as needed */
  border-bottom-left-radius: 50px;
  /* Adjust the value as needed */
}

.tv-etikaf {
  color: white;
  background-color: #7A1777;
  text-align: center;
  padding: 40px;
  border-top-right-radius: 50px;
  /* Adjust the value as needed */
  border-bottom-right-radius: 50px;
  /* Adjust the value as needed */
}

.ciri-kanan {
  padding: 0px;
  text-align: center;
}

.etv-container {
  display: flex;
  padding-top: 70px;

}

.etv-image {
  flex: 1;
  display: flex;
  justify-content: center;
  /* Center horizontally */
  padding-top: 40px;
}

.etv-text {
  flex: 1;
}

.img-etv {
  width: 400px;
  height: 100%;
}



.kos-harga {
  font-size: 18px;
  font-weight: normal;
}

.ciri-icon {
  border: 1px solid #ccc;
  /* 1px solid border with color #ccc */
  padding: 20px;
  /* Optional: Add padding inside the border */
  width: 100%;
}


.ciri-icon h4 {
  padding: 0px;
  margin: 0px;
}


.ciri-icon p {
  padding: 0px;
  margin: 0px;
}


.sub-kos {
  margin: 0 20px;
}


.mula-kanan button {
  margin-top: 40px;
  font-size: 18px;
  padding: 10px 40px;
  height: auto;

}

button {
  height: 40px;
  border-radius: 20px;
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;
  /* Add a border to match the outline */
  padding: 0 30px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-weight: bold;
  /* Make the text bold */
}

button:hover {
  background-color: #7A1777;
  color: white;
}


@media (max-width: 768px) {



  .about-top {
    width: 100%;
  }

  .menu-title {
    font-size: 14px;
  }

  .top-menu li {
    margin-right: 0px !important;
  }

  .about-top {
    padding-top: 90px;
  }

  .icon-close {
    margin: 0 10px;
  }


  .pengenalan-atas,
  .pengenalan-masjid,
  .mula-container,
  .ciri-container,
  .etv-container,
  .kos-etikaf {
    flex-direction: column;
    padding: 40px 10px;
  }

  .mula-kiri{
    display: flex;
    justify-content: center;
  }

   .mula-kanan {
    text-align: center;
  }

  .top-buttons button {
    margin-right: 1px !important;
    padding-left: 10px;
  }

  .pengenalan-container {
    padding-top: 10px;
  }

  .pengenalan-masjid {
    padding: 10px;
  }


  .pengenalan-masjid-bawah,
  .img-masjid,
  .img-jemaah,
  .img-mula,
  .img-konsep {
    width: 300px;
  }

  .ciri-icon {
    width: 100%;

  }


  .ciri-kanan {
    width: 100%;
    margin: 10px;
  }



  .pengenalan-container {
    padding: 10px !important;
  }

  .top-menu {
    padding: 0px;
  }

  .sistem-etikaf {

    border-top-left-radius: 50px;
    /* Adjust the value as needed */
    border-top-right-radius: 50px;
    border-bottom-left-radius: 0px;

  }

  .tv-etikaf {
    border-top-right-radius: 0px;

    border-bottom-left-radius: 50px;
    border-bottom-right-radius: 50px;
    /* Adjust the value as needed */
  }

  .fixed-button {
    bottom: 10px;
    /* Adjust as needed */
    right: 10px;
    /* Adjust as needed */
  }


}