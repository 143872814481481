body {
  background-color: #f8f8f8;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
}

.container {
  width: 100%;
  margin: 0 auto;
}


.app-header {

  height: 76px;
  position: fixed;  /* Fixes the header to the top of the viewport */
  top: 0;           /* Positions the header at the top */
  left: 0;          /* Ensures the header spans the entire width */
  right: 0;         /* Ensures the header spans the entire width */
  z-index: 1000;    /* Ensures the header stays above other content */
}

.content-header {
  width: 70%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 0px;
  height: 100%;


}

.App-logo {
  height: 40px;
  cursor: pointer;
}


.icon-text-container {
  display: inline-block;
  position: relative;
}

.icon-import {
  margin: 0 10px 0 0;
  cursor: pointer;
  transition: transform 3s ease; /* Smooth animation */
}

.icon-text {
  display: none;
  position: absolute;
  right: 50%; 
  top: 50%;
  transform: translateY(-50%);
  white-space: nowrap;
  padding: 5px;
  border-radius: 3px;
  font-size: 14px;
}

.icon-text-container:hover .icon-text {
  display: inline-block;
}


nav {
  display: flex;
  align-items: center;
}

nav span {
  margin-right: 20px;
  cursor: pointer;
}

nav button {
  height: 40px;
  border-radius: 20px;  /* Adjust this to make the edges rounder */
  background-color: #fff;
  color: #000;
  border: 1px solid #ccc;  /* Add a border to match the outline */
  padding: 0 20px;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  font-weight: bold;  /* Make the text bold */
}

nav button:hover {
  background-color: #7A1777;
  color: #fff;
  border-color: #7A1777;  /* Change border color on hover */
}


main {
  width: 70%;
  margin: 0 auto;
}

@media (max-width: 768px) {
  main {
    width: 100%;
  }

  .container {
    width: 100%;
    padding: 0 0px;
  }


  
.content-header {
  width: 100%;
 


}
}

footer {
  text-align: center;
  padding: 20px 0;
  background-color: #f8f8f8;
  border-top: 1px solid #e1e1e1;
  font-size: 12px;
}


@media (max-width: 450px) {
 
.App-logo {
  height: 30px;
  cursor: pointer;
  margin-left: 10px;

}




.app-header {
  height: 55px;
  padding: 0px;
  margin: 0px;
  z-index: 9999;
}


nav button {
  height: 35px;
  padding: 0 10px;
 margin-right: 20px;
}
}