/* src/components/Footer.css */

.footer-container {
  background-color: #f8f8f8;
  padding: 20px 0;
  text-align: center;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto 60px;
}

.footer-etikaf {
  padding-top: 12px;
}

.footer-icon {
  width: 40px;
  cursor: pointer;
  margin: 0 0 10px 0;
}

.footer-main {
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-center {
  display: flex;
  flex-wrap: wrap;
  /* This allows the items to wrap into multiple rows */
  justify-content: center;
  /* Centers the items horizontally */
  align-items: center;
  /* Aligns the items vertically */
  gap: 10px;
  /* Adds space between items */
}

.footer-center a {
  width: calc(33% - 30px);
  /* Each item takes up 1/3 of the row, accounting for the gap */
  text-align: center;
  /* Centers the text within each item */
  padding: 10px;
  /* Adds some padding for spacing */
  text-decoration: none;
  /* Removes underline from the links */
  color: #000;
  /* Set text color (you can customize this) */
  background-color: #f2f2f2;
  /* Light background color for the items */
  border-radius: 5px;
  /* Rounds the corners of the items */
  transition: background-color 0.3s ease;
  /* Smooth transition for hover effect */
}

.footer-center a:hover {
  background-color: #ddd;
  /* Changes background color on hover */
}


.footer-logos {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0px;
  margin-left: 10px;

}

.footer-socials {
  display: flex;
  justify-content: start;
  align-items: center;
}

.footer-logo {
  width: 30px;
  height: auto;
  cursor: pointer;
  padding: 0 10px;
}

.footer-store {
  width: 120px;
  height: auto;
  cursor: pointer;
}

.footer-play {
  width: 148px;
  height: auto;
  cursor: pointer;
}



.footer-text {
  font-size: 14px;
  color: #666;
}


@media (max-width: 768px) {
  .footer-container {
    width: 100%;
    padding: 0px;
    flex-direction: column;
  }

  .footer-store {
    width: 95px;
  }

  .footer-play {
    width: 120px;
  }

  .footer-icon {
    width: 30px;
    cursor: pointer;
    margin: 0 0 10px 0;
  }


  .footer-socials {
    justify-content: center;
    flex-direction: column;
    padding-bottom: 30px;
  }

}