.kemaskini-container {
    padding: 20px;
    margin-top: 80px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    min-height: 70vh;
}

.kemaskini-container h2 {
    color: black;
    text-align: start;
}

.kemaskini-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.kemaskini-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.kemaskini-date,
.kemaskini-type {
    font-size: 14px;
    color: #777;
    min-width: 130px;
    text-align: left;
}

.kemaskini-title {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    flex: 1;
    margin: 0 40px 0 0;
}

.kemaskini-description {
    font-size: 14px;
    color: #555;
    flex: 2;
}

@media (max-width: 768px) {
    .kemaskini-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .kemaskini-date,
    .kemaskini-type,
    .kemaskini-title,
    .kemaskini-description {
        min-width: unset;
        text-align: left;
        margin-bottom: 5px;
    }
}