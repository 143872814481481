/* Ensure prayer times styling remains intact */
.countdown-container {
  display: flex;
  gap: 5px; /* Adjust the gap between boxes as needed */
  margin: 0 0 0 10px;

}

.next-prayer-container {
  display: flex;
  align-items: center;

}

.countdown-box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
  background-color: #7A1777; /* Adjust the background color as needed */
  border-radius: 5px; /* Adjust the border radius as needed */
  font-size: 14px; /* Adjust the font size as needed */
  color: white; /* Adjust the text color as needed */
  font-weight: 600;
}


.prayer-times-container {
  display: flex;
  flex-wrap: wrap;
}

.prayer-times-item:hover {
background-color: #fcfcfc;
}

.prayer-times-item {
  flex: 1 1 100%;
  padding-left: 15px;
  border-right: 1px solid #ccc;
}

.prayer-times-item:last-child {
  border-right: none;
}


.current-prayer {
  color: #7A1777; 
  font-weight: bold;
}

/* Styles for the search container */
.search-container {
  position: relative;
  border-radius: 5px;
  width: 100%; /* Adjust to fit parent */
  padding: 10px 0;
  z-index: 1000;
  overflow: visible; /* Ensure overflow is visible */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-input-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; /* Full width to center the elements */
}

.search-input {
  width: 80%; /* Adjust width to fit */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px; /* Add margin for spacing */
}

.zones-list-container {
  display: none; /* Initially hidden */
  position: absolute; /* Position absolute to overlay */
  top: 50px; /* Adjust as needed */
  left: 7.4%; /* Adjust to align with input */
  width: 80%; /* Match input width */
  overflow-y: auto;
  max-height: 150px; /* Adjust max height */
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  z-index: 1000; /* Ensure it stays on top */
}

.zones-list-container.show {
  display: block; /* Show when 'show' class is added */
}

.state-title {
  font-weight: bold;
  margin-bottom: 5px;
  background-color: #f0f0f0;
  padding: 5px;
  border-radius: 3px;
}

.zone-list {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

.zone-list li {
  padding: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.zone-list li:hover {
  background-color: #e0e0e0;
}

.close-button {
  background-color: #7A1777;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
}

.close-button:hover {
  background-color: #9A1999;
}

.icon-right {
  margin: 3px 5px 0 0;
  transition: transform 0.3s ease; /* Add transition for smooth animation */
}

.icon-right:hover {
  transform: translateX(5px); /* Move the element to the right by 5px on hover */
}

.icon-container {
  cursor: pointer;
  display: flex;
  align-items: center;

  
}

/* .location-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px; 
} */

.icon-container:hover {
  color: black;
  font-weight: bolder;
}

@media (max-width: 450px) {
  .prayer-times-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }

  .prayer-times-item {
    padding-left: 0;
    border-right: none !important;
  }

  .prayer-times-name {
    font-size: 12px;
  }


  .info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;
    padding: 15px;
    color: grey;
  }

  
.next-prayer-container {
  padding: 10px 0 0 0;

}



}
