.title-area {
  margin: 0 0 20px 0;
  font-size: 20px;
  font-weight: bold;
}

.container-body {
  display: flex;
  flex-wrap: wrap;
}

.column-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.kuliah {
  flex: 3;
}

.etv {
  flex: 1;
}

.cards-container {
  gap: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.card {
  position: relative;
  width: 100%;
  height: 300px;
  background: white;
  border-radius: 8px;
  overflow: hidden;
}

.card-image img {
  width: 100%;
  height: 80px;
  object-fit: cover;
}

.card-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 190px;
}

.card-header {
  flex-grow: 1;
}

.card-header h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0 0 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.card-header .date {
  color: #7a1777;
  font-size: 14px;
  margin: 5px 0;
}

.card-header .mosque-name {
  color: grey;
  font-size: 12px;
  font-weight: 100;
  margin: 0px 0;
  max-width: 200px;
  /* Adjust as needed */
}

.card-header .ustaz-name {
  font-weight: 500;
}

.card-footer {
  display: flex;
  align-items: center;
  position: relative;
  bottom: 0px;
}

.card-footer .subject {
  background-color: #7a1777;
  color: white;
  padding: 2px 8px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: bold;
}

.card-footer .title {
  font-size: 12px;
  margin-left: 8px;
  flex-grow: 1;
}

.avatar-wrapper {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 78px;
  height: 78px;
  background: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatar {
  width: 74px;
  height: 74px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cards-etv-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.card-etv {
  position: relative;

  display: flex;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;
  margin: 5px;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.card-etv:hover {
  transform: scale(1.05);
}

.image-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio (9 / 16 * 100) */
  overflow: hidden;
}

.image-etv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  /* Ensure center alignment */
  /* Add padding for better readability */
  font-size: 16px;
  font-weight: bold;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  line-height: 1.4;
  /* Adjust line height for readability */
  word-wrap: break-word;
  /* Ensure text wraps properly */
  white-space: normal;
  /* Allow multiple lines */
  overflow-wrap: break-word;
  /* Ensure long words break */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  /* Enable text wrapping */
}

.card-etv:hover .overlay {
  opacity: 1;
}




.content {
  flex: 1;
  padding: 0 15px;
  color: black;
  font-size: 14px;
}




@media (max-width: 1200px) {



  .content {
    padding: 10px;
    text-align: start;
  }



  .column-body {
    flex: 1 1 100%;
  }

  .kuliah {
    order: 1;
  }

  .etv {
    order: 2;
  }

  .cards-container {
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }

  .cards-etv-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* Fix: Ensure 2 columns */
    gap: 10px;
  }
}

@media (max-width: 600px) {
  .cards-container {
    grid-template-columns: 1fr 1fr;
  }


  .card {

    height: 350px;

  }

  .card-content {
    padding: 20px 10px;

    height: 220px;
  }

  .cards-etv-container {
    grid-template-columns: repeat(2, 1fr);

  }
}