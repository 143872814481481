.hairline {
    border: none;
    border-top: 1px solid #ccc;
    margin: 10px 0;
  }
  
  .mosque-container {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 10px;
    padding: 10px;
    scrollbar-width: none; /* For Firefox */
    -ms-overflow-style: none;  /* For Internet Explorer and Edge */
  }
  
  .mosque-container::-webkit-scrollbar {
    display: none;  /* For Chrome, Safari, and Opera */
  }
  
  .mosque-card {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 5px 10px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: grab; /* Change cursor to grab */
  }
  
  .mosque-card:active {
    cursor: grabbing; /* Change cursor to grabbing when active */
  }
  
  .mosque-image {
    width: 30px; /* Smaller size */
    height: 30px; /* Smaller size */
    border-radius: 50%; /* Makes the image round */
    margin-right: 10px;
  }
  
  .mosque-name {
    font-weight: bold;
    font-size: 14px; /* Smaller font size */
    overflow: hidden;
    white-space: nowrap;
    max-width: 90px; /* Adjust as needed */
    text-overflow: ellipsis;

  }
  
  .more-button {
    height: 40px;
    border-radius: 20px;
    background-color: #fff;
    color: #000;
    border: 1px solid #ccc;
    padding: 0 20px;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    font-weight: bold;
    flex-shrink: 0;
    white-space: nowrap;
  }
  
  .more-button:hover {
    background-color: #7A1777;
    color: #fff;
    border-color: #7A1777;
  }
  