.main-container {
    width: 70%;
    margin: 0 auto;
}


@media (max-width: 768px) {
    .main-container {
        width: 100%;
    }

}