/* Container styles to avoid content overlapping the header */
.privasi-container {
    padding: 20px;
    margin-top: 80px;
    /* Adjust this value if your header height changes */
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

/* Heading styles */
.privasi-container h1,
.privasi-container h2 {
    color: black;
}

/* Link styles */
.privasi-container a {
    color: #007BFF;
    text-decoration: none;
}

.privasi-container a:hover {
    text-decoration: underline;
}