.hubungi-container {
    padding: 20px;
    margin-top: 80px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #333;
    background-color: #f9f9f9;
    border-radius: 8px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    min-height: 70vh;
}

.hubungi-container h2 {
    color: black;
    text-align: start;
    margin-bottom: 20px;
}

.hubungi-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.hubungi-item {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.hubungi-name {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
    flex: 1;
}

.hubungi-details {
    display: flex;
    flex-direction: column;
    flex: 2;
}

.hubungi-email,
.hubungi-phone {
    font-size: 16px;
    color: #555;
    display: flex;
    align-items: center;
    gap: 5px;
}

.hubungi-icon {
    font-size: 18px;
    color: #007BFF;
}

@media (max-width: 768px) {
    .hubungi-item {
        flex-direction: column;
        align-items: flex-start;
    }

    .hubungi-name {
        font-size: 16px;
    }

    .hubungi-details {
        width: 100%;
    }

    .hubungi-email,
    .hubungi-phone {
        font-size: 14px;
    }
}